export const PERMISSION_TYPE = {
    ADMIN: 'admin',
    COSTS: 'costs',
    LOGS: 'logs',
    NPD: 'npd',
    PDD: 'pdd',
}

const ALL_PERMISSIONS = [
    PERMISSION_TYPE.ADMIN,
    PERMISSION_TYPE.COSTS,
    PERMISSION_TYPE.LOGS,
    PERMISSION_TYPE.NPD,
    PERMISSION_TYPE.PDD,
];

export const PERMISSIONS = {
    'ada.alexis': [
        PERMISSION_TYPE.NPD,
    ],
    'agustina.beltramino': [
        PERMISSION_TYPE.COSTS,
    ],
    'alexander': ALL_PERMISSIONS,
    'andrew': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
    ],
    'ben.konides': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
        PERMISSION_TYPE.PDD,
    ],
    'catalin.bozianu': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
    ],
    'clien.abellanosa': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.NPD,
    ],
    'cynthia.tsai': [
        PERMISSION_TYPE.PDD,
    ],
    'florencia.zaccaria': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
    ],
    'glenn.deciar': [
        PERMISSION_TYPE.NPD,
    ],
    'grace': [
        PERMISSION_TYPE.NPD,
    ],
    'igor.gabelev': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
        PERMISSION_TYPE.NPD,
        PERMISSION_TYPE.PDD,
    ],
    'joben.mariano': [
        PERMISSION_TYPE.NPD,
    ],
    'juan.gomez': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
    ],
    'judyanne.painagan': [
        PERMISSION_TYPE.NPD,
    ],
    'julie.engel': ALL_PERMISSIONS,
    'kristine.ingan': [
        PERMISSION_TYPE.NPD,
    ],
    'lalaine.mabale': [
        PERMISSION_TYPE.NPD,
    ],
    'leon.dore': ALL_PERMISSIONS,
    'leonardo.maia': ALL_PERMISSIONS,
    'lucas.buarque': [
        PERMISSION_TYPE.COSTS,
    ],
    'mackenzie.flynn': [
        PERMISSION_TYPE.ADMIN,
        PERMISSION_TYPE.LOGS,
        PERMISSION_TYPE.PDD,
        PERMISSION_TYPE.NPD,
    ],
    'marifel': [
        PERMISSION_TYPE.NPD,
    ],
    'mario.figueroa': [
        PERMISSION_TYPE.NPD,
    ],
    'markneil': [
        PERMISSION_TYPE.NPD,
    ],
    'maryann.ulic': [
        PERMISSION_TYPE.NPD,
    ],
    'maryrose.elcano': [
        PERMISSION_TYPE.NPD,
    ],
    'mcjill': [
        PERMISSION_TYPE.NPD,
    ],
    'melchor.belo': [
        PERMISSION_TYPE.NPD,
    ],
    'minerva.mada': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
    ],
    'michelle.dailing': [
        PERMISSION_TYPE.PDD,
        PERMISSION_TYPE.NPD,
    ],
    'neil.luna': [
        PERMISSION_TYPE.NPD,
    ],
    'neto': ALL_PERMISSIONS,
    'paula.falomir': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.NPD,
    ],
    'rene.manqueros': ALL_PERMISSIONS,
    'rhea.mariano': [
        PERMISSION_TYPE.NPD,
    ],
    'rizza.joseph': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.NPD,
    ],
    'samuel.ocampo': ALL_PERMISSIONS,
    'sean.mcelroy': [
        PERMISSION_TYPE.COSTS,
    ],
    'sonam.virk': ALL_PERMISSIONS,
    'steve.neuda': [
        PERMISSION_TYPE.NPD,
    ],
    'tina': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
        PERMISSION_TYPE.PDD,
        PERMISSION_TYPE.NPD,
    ],
    'todd': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.PDD,
    ],
    'veronica.calido': [
        PERMISSION_TYPE.NPD,
    ],
    'wendell': ALL_PERMISSIONS,
    'edson.silva': ALL_PERMISSIONS,
};
